
.rotate_telephone {
    transform: rotate(90deg);
}
.text-capitalize {
    text-transform: capitalize !important;
}
.MuiTabs-indicator {
    /* background-color: #1CBE93  !important; */ /* Custom background for the tab bar */
    /* color: #1CBE93  !important; */
}
  
.customTab {
    color: #1CBE93  !important;
    font-weight: 600 !important;
}